import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import "react-toastify/dist/ReactToastify.css";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { useEffect } from "react";
import BannerOverlay from "../components/BannerOverlay";
import waze from "../assets/svg/waze-white.svg";

const Container = styled.div`
    background: black;
    display: flex;
    padding: 4rem 100px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${mobile({ padding: "0" })}
    ${tablet({ padding: "0" })}
`;

const Subtitle = styled.h1`
    font-family: FuturaPT-Demi;
    font-size: 1.5rem;
    text-align: center;
    color: gray;
    padding-bottom: 1.5rem;
    ${mobile({
        textAlign: "center",
        fontSize: "1.2rem",
    })}

    ${tablet({
        textAlign: "center",
        fontSize: "1.2rem",
    })}
`;

const Address = styled.div`
    font-size: 1.5rem;
    text-align: center;
    color: #ac997b;
    font-weight: 600;
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "1rem 1rem 1rem 1rem",
    })}

    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "1rem 1rem 1rem 1rem",
    })}
`;

const LotContainer = styled.div`
    padding: 1rem 0rem;
    ${mobile({
        display: "block",
        padding: "1rem",
        marginBottom: "1rem",
    })}

    ${tablet({
        display: "block",
        padding: "1rem",
        marginBottom: "1rem",
    })}
`;

const Lot = styled.div`
    font-size: 1.2rem;
    color: white;
    text-align: center;
    padding: 0rem 0rem 0rem 0rem;
    ${mobile({
        display: "block",
        fontSize: "1.2rem",
        textAlign: "center",
    })}

    ${tablet({
        display: "block",
        fontSize: "1.2rem",
        textAlign: "center",
    })}
`;

const Icon = styled.img`
    display: block;
    margin: 10px auto 0;
    width: 40px;
    height: 40px;
`;

const Melaka = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/dndream/Branch-Melaka_UtxqDdCs3.jpg?updatedAt=1739987835415");
    margin: 3rem;
    background-size: cover;
    background-position: top;
    width: 60vh;
    height: 40vh;
    ${mobile({
        margin: "0rem",
        width: "100%",
    })}

    ${tablet({
        margin: "0rem",
        width: "100%",
    })}
`;

const KL = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/dndream/WhatsApp-Image-2025-02-25-at-15.36_AAx1s3IaZ.png?updatedAt=1740491316830");
    margin: 3rem;
    background-size: cover;
    background-position: top;
    width: 60vh;
    height: 40vh;
    ${mobile({
        margin: "0rem",
        width: "100%",
    })}

    ${tablet({
        margin: "0rem",
        width: "100%",
    })}
`;

const JB = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/dndream/Branch-JB_FsamxVg5p.jpg?updatedAt=1739987835423");
    margin: 3rem;
    background-size: cover;
    background-position: top;
    width: 60vh;
    height: 40vh;
    ${mobile({
        margin: "0rem",
        width: "100%",
    })}

    ${tablet({
        margin: "0rem",
        width: "100%",
    })}
`;


const Ipoh = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/dndream/Branch-Ipoh_azxTWADAD.jpg?updatedAt=1739987835411");
    margin: 3rem;
    background-size: cover;
    background-position: top;
    width: 60vh;
    height: 40vh;
    ${mobile({
        margin: "0rem",
        width: "100%",
    })}

    ${tablet({
        margin: "0rem",
        width: "100%",
    })}
`;

const AboutContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 3rem;
    ${mobile({ display: "block", margin: "0" })}

    ${tablet({ display: "block", margin: "0" })}
`;

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <Header />
            <BannerOverlay
                imageUrl="https://ik.imagekit.io/dnddecpho/dndream/D&D_Website_WebBanner_1920x500px_PC5_dHeFNDqxfZ.jpg?updatedAt=1731826250680"
                title=""
            ></BannerOverlay>
            <Container>
            <div className="md:pt-1 lg:pt-10 py-4 text-3xl lg:text-4xl uppercase primalbrown hidden lg:block">D&D OFFICES</div>
                <AboutContainer>
                <div>
                        <KL></KL>
                     
                        <LotContainer>
                            {" "}
                            <Address>D&D Kuala Lumpur</Address>
                            <Subtitle>(Headquarter)</Subtitle>
                            <Lot>G22, 28 Blvd, Jln Perdana 3/10,</Lot>
                            <Lot>Pandan Perdana, 55300</Lot>
                            <Lot>Kuala Lumpur</Lot>
                            <a href="https://www.waze.com/zh/live-map/directions/my/selangor/kuala-lumpur/dandd-real-estate-sdn-bhd?to=place.ChIJp8uMi-43zDERREDXbBhDUKE" target="_blank" rel="noopener noreferrer">
                                <Icon src={waze} alt="waze icon" />
                            </a>
                        </LotContainer>
                    </div>
                    <div>
                        <JB></JB>
                        <LotContainer>
                            {" "}
                            <Address>D&D Johor Bahru</Address>
                            <Subtitle>(Branch)</Subtitle>
                            <Lot>1-03, Jalan Kota, 3, ,</Lot>
                            <Lot>Taman Cahaya Kota Puteri, 81750</Lot>
                            <Lot>Johor Darul Ta'zim</Lot>
                            <a href="https://www.waze.com/zh/live-map/directions/my/johor-darul-tazim/masai/dandd-real-estate-(-jb-branch-)?to=place.ChIJBUUrLo1r2jER09MPlA1HtgY" target="_blank" rel="noopener noreferrer">
                                <Icon src={waze} alt="waze icon" />
                            </a>
                        </LotContainer>
                    </div>
                    <div>
                        <Melaka></Melaka>
                        <LotContainer>
                            {" "}
                            <Address>D&D Melaka</Address>
                            <Subtitle>(Branch)</Subtitle>
                            <Lot>1-1, Jalan KSB 17,</Lot>
                            <Lot>Melaka, 75200</Lot>
                            <Lot>Melaka</Lot>
                            <a href="https://www.waze.com/zh/live-map/directions/my/melaka/melaka/danddream-property-group-(mlk-branch)?to=place.ChIJ0xVx6ELx0TER-hydhWQiaLA" target="_blank" rel="noopener noreferrer">
                                <Icon src={waze} alt="waze icon" />
                            </a>
                        </LotContainer>
                    </div>
                    <div>
                        <Ipoh></Ipoh>

                        <LotContainer>
                            {" "}
                            <Address>D&D Ipoh</Address>
                            <Subtitle>(Branch)</Subtitle>
                            <Lot>No.39C, Third Floor, Hala Pusat Perdagangan Canning,</Lot>
                            <Lot>1, Jln Pusat Perdagangan Canning 2, 30350</Lot>
                            <Lot>Ipoh, Perak</Lot>
                            <a href="https://www.waze.com/zh/live-map/directions/my/perak/ipoh/dandd-property-group-(ipoh)?to=place.ChIJ7cIhhintyjERNrbEKXqexlU" target="_blank" rel="noopener noreferrer">
                                <Icon src={waze} alt="waze icon" />
                            </a>
                        </LotContainer>
                    </div>
                </AboutContainer>
            </Container>
            <Footer></Footer>
        </>
    );
};

export default Contact;
