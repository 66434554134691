export const Preview = () => {
    return (
        <div className="lg:px-1 overflow-hidden lg:pt-8 py-8 pb-16 bg-black">
            <div
                className="lg:px-3 lg:mb-4 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center tracking-tighter text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 mb-4">
                    D&Dream Property Group was established in the year 2017,
                    October 1st and constantly expanding the team nationwide to
                    be the leading property agency in Malaysia. Through the
                    years of hard work and dedication, D&Dream Property Group
                    has obtained recognition from the market:
                </div>

                <img
                    src="https://venti-fans.com/wp-content/uploads/2025/03/DD_WebsiteIcons-02_icF9qp5Yn-scaled.webp"
                    alt=""
                />
            </div>

            <div
                className="lg:px-3 lg:mb-16 my-3 py-2 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    Vision
                </div>
                <div className="md:pt-1 lg:pt-4 py-1">
                    To be the largest property agent in Malaysia, emphasizing on
                    giving profit and power while holding on to the principle of
                    equality.
                </div>
            </div>

            <div
                className="lg:px-3 lg:mb-16 my-3 py-2 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    Mission
                </div>
                <div className="md:pt-1 lg:pt-4 py-1">
                    To provide sufficient properties to sell! To ensure 100%
                    fast commission is paid without delay! To enjoy life to the
                    fullest!
                </div>
            </div>

            <hr />
            <div
                className="lg:px-3 lg:mb-8 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    BENEFITS
                </div>
                <div className="grid lg:grid-cols-3 lg:p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground font-semibold">
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-40"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteBenefitIcons_%20_a-Bn2frvL.png?updatedAt=1739983372980"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-2 py-1">
                            Car Subsidy Up To RM12,000
                        </div>
                        <div className="py-1">12,000的汽车津贴</div>
                    </div>
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-40"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteBenefitIcons-02_dMX3UZyodF.png?updatedAt=1739983372983"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-2 py-1">
                            100% All Commissions
                        </div>
                        <div className="py-1">100%经纪，领导，至尊领导佣金</div>
                    </div>
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-40"
                                src="https://venti-fans.com/wp-content/uploads/2025/03/DD_WebsiteBenefitIcons-03_Vsy084Jmf.webp"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-2 py-1">
                            3 Days 100% Comm.
                        </div>
                        <div className="py-1">3天100%佣金</div>
                    </div>
                </div>

                <div className="grid lg:grid-cols-3 lg:p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground font-semibold">
                    <div className="block mr-4">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-40"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteBenefitIcons-04_ky6dqn1a-.png?updatedAt=1739983372808"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-2 py-1">
                            Oversea Incentive Trips
                        </div>
                        <div className="py-1">海外旅行</div>
                    </div>
                    <div className="block mr-4">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-40"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteBenefitIcons-05_AOy9Mn09CF.png?updatedAt=1739983372781"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-2 py-1">
                            Exclusive D&D Projects
                        </div>
                        <div className="py-1">简易开单项目</div>
                    </div>
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-40"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteBenefitIcons-06_vdlR23Xqa.png?updatedAt=1739983372906"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-2 py-1">
                            Group Profit Sharing
                        </div>
                        <div className="py-1">富过三代计划</div>
                    </div>
                </div>

                <div className="grid lg:grid-cols-3 lg:p-8 w-full h-full px-6 rounded-lg block greyOpaqueBackground font-semibold">
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-40"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteBenefitIcons-07_vZ8aIgkhe.png?updatedAt=1739983372763"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-2 py-1">
                            1-1 Leadership & Team Trainings
                        </div>
                        <div className="py-1">一对一培训计划</div>
                    </div>
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-40"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteBenefitIcons-08_CMKezNeaV.png?updatedAt=1739983372768"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-2 py-1">
                            Image Branding Training
                        </div>
                        <div className="py-1">形象品牌培训课程</div>
                    </div>
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-40"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteBenefitIcons-09_hmemZzAqUa.png?updatedAt=1739983372772"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-2 py-1">
                            Relax Co-Working Space Provided
                        </div>
                        <div className="py-1">舒适共用工作空间</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
