import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";

const Container = styled.div`
    width: 100%;
    background: black;
    height: 100vh;
    overflow: hidden;
    ${mobile({ display: "none" })}
    ${tablet({ height: "100%" })}
`;

const VideoBg = styled.video`
    z-index: -1;
    width: 100%;
    height: auto;
    ${mobile({ display: "none" })}
`;

const Video = (url) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to check if the user is on a mobile device
        const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        // Set the state based on whether the user is on a mobile device
        setIsMobile(isMobileDevice());
    }, []);

    return (
        <Container>
            <VideoBg autoPlay={!isMobile} loop>
                <source src={url.url} type="video/mp4" />
            </VideoBg>
        </Container>
    );
};

export default Video;
