export const Mission = () => {
    return (
        <div className="lg:px-1 overflow-hidden lg:pt-8 py-8 pb-16 bg-black">
            <div
                className="lg:px-3 lg:mb-4 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center tracking-tighter text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 mb-4">
                    D&Dream Property Group was established in the year 2017,
                    October 1st and constantly expanding the team nationwide to
                    be the leading property agency in Malaysia. Through the
                    years of hard work and dedication, D&Dream Property Group
                    has obtained recognition from the market:
                </div>

                <img
                    src="https://venti-fans.com/wp-content/uploads/2025/03/DD_WebsiteIcons-02_icF9qp5Yn-scaled.webp"
                    alt=""
                />
            </div>

            <div
                className="lg:px-3 lg:mb-8 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    Vision
                </div>
                <div className="md:pt-1 lg:pt-4 py-1">
                    To be the largest property agent in Malaysia, emphasizing on
                    giving profit and power while holding on to the principle of
                    equality.
                </div>
            </div>

            <div
                className="lg:px-3 lg:mb-8 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    Mission
                </div>
                <div className="md:pt-1 lg:pt-4 py-1">
                    To provide sufficient properties to sell! To ensure 100%
                    fast commission is paid without delay! To enjoy life to the
                    fullest!
                </div>
            </div>

            <div
                className="lg:px-3 lg:mb-8 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown mb-2">
                    CORE VALUE
                </div>
                <img
                    className="lg:h-[400px]"
                    src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-16_IKeS5pYym.png?updatedAt=1719760143986"
                    alt="ptp1comm"
                />

                <div className="md:pt-1 lg:pt-4">
                    <span className="primalbrown">A</span> - Agressive / Execution / Result Oriented
                </div>
                <div className="md:pt-1">
                    <span className="primalbrown">L</span> - Leave No Man Behind
                </div>
                <div className="md:pt-1">
                    <span className="primalbrown">L</span> - Loyalty
                </div>
                <div className="md:pt-1">
                    <span className="primalbrown">O</span> - Outstanding
                </div>
                <div className="md:pt-1">
                    <span className="primalbrown">U</span> - Unity
                </div>
                <div className="md:pt-1">
                    <span className="primalbrown">T</span> - Thankful / Transparent / Trust
                </div>
            </div>

            <div
                className="lg:px-3 lg:mb-4 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center tracking-tighter text-white mx-auto max-w-3xl bg-black"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <img
                    src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-22_OxRTQgoyy.gif?updatedAt=1719761135594"
                    alt=""
                />
            </div>
        </div>
    );
};
