import Home from "./pages/Home";
import About from "./pages/About";
import Team from "./pages/Team";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import Training from "./pages/Training";
import Contact from "./pages/Contact";

const GlobalContainer = styled.div`
    font-family: "FuturaPT-Demi";
`;

const App = () => {
    return (
        <GlobalContainer>
            <a
                href="https://api.whatsapp.com/send?phone=60166880996"
                target="_blank"
                rel="noreferrer"
                className="fixed w-16 h-16 bottom-10 right-10 bg-green-500 text-white rounded-full flex justify-center items-center text-2xl shadow-md z-10"
            >
                <FaWhatsapp size={36} />
            </a>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/about">
                        <About />
                    </Route>
                    <Route exact path="/team">
                        <Team />
                    </Route>
                    <Route exact path="/contact">
                        <Contact />
                    </Route>
                    <Route exact path="/training">
                        <Training />
                    </Route>
                </Switch>
                <ToastContainer
                    style={{ width: "fit-content", overflow: "hidden" }}
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Router>
        </GlobalContainer>
    );
};

export default App;
