import { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import MobileNavbar from "./MobileNavbar";

import { IconFlagCN, IconFlagUS } from "material-ui-flags";

const Routes = [
    {
        name: "Home",
        path: "/",
    },
    {
        name: "About",
        path: "/about",
    },
    {
        name: "Team",
        path: "/team",
    },
    {
        name: "Training",
        path: "/training",
    },
    {
        name: "Contact",
        path: "/contact",
    },
    {
        name: "Login",
        path: "https://dnduser.com",
    },
];

export const Header = () => {
    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // Determine the scroll position
            const scrollPosition = window.scrollY;

            // Set a threshold scroll position where the header becomes fixed
            const threshold = 100;

            // Update the state based on the scroll position
            setIsFixed(scrollPosition > threshold);
        };

        // Add an event listener to the window's scroll event
        window.addEventListener("scroll", handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const headerClass = `lg:flex ${
        isFixed ? "fixed top-0 transition-all duration-300 ease-in-out" : ""
    } hidden items-center justify-between flex-wrap bg-black w-full z-10`;
    return (
        <>
            <nav
                className={headerClass}
                style={{ fontFamily: "FuturaPT-Demi" }}
            >
                <div
                    className="w-full flex-grow lg:items-center lg:w-auto hidden lg:pt-6 lg:pt-0"
                    id="nav-content"
                    style={{ display: "flex" }}
                >
                    <ul className="list-reset lg:flex justify-start flex-1 items-center ml-4">
                        {/* <IconButton>
                        <IconFlagCN />
                        <div className="text-white text-lg ml-2 cursor-pointer">
                            简体中文
                        </div>
                    </IconButton>
                    <IconButton>
                        <IconFlagUS />
                        <div className="text-white text-lg ml-2 cursor-pointer">
                            English{" "}
                        </div>
                    </IconButton> */}
                    </ul>
                    <ul className="list-reset lg:flex justify-center flex-1 items-center">
                        <a href="/">
                            {" "}
                            <img
                                className="max-h-8 md:max-h-10 lg:max-h-12"
                                src={
                                    "https://ik.imagekit.io/dnddecpho/dndream/DD_LogoGold-300x53_9YWAdjFzX.png?updatedAt=1706110249831"
                                }
                                alt="desktop-logo"
                            ></img>
                        </a>
                    </ul>
                    <ul className="list-reset inline-flex lg:flex justify-end flex-1 items-center">
                        <div className="lg:my-8 my-1 gap-2 grid grid-cols-6">
                            {Routes.map((route, index) => (
                                <a
                                    className="text-white text-center text-lg mr-4 cursor-pointer"
                                    href={route.path}
                                >
                                    {route.name}
                                </a>
                            ))}
                        </div>
                    </ul>
                </div>
            </nav>
            <MobileNavbar></MobileNavbar>
        </>
    );
};
