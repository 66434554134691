export const TeamContent = () => {
    return (
        <div className="lg:px-1 overflow-hidden lg:pt-8 py-8 pb-16 bg-black">
            <div
                className="lg:px-3 lg:mb-4 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center tracking-tighter text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 mb-4">
                    Our team members have expanded drastically over the years in Kuala Lumpur, Johor Bahru, Melaka & Ipoh. We have successfully nurture a group of young entrepreneurs in pioneering the real estate career.
                </div>

                <img
                    src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteExtract_LocationsAll_0RVPTddJ0.png?updatedAt=1739982524411"
                    alt=""
                />
            </div>



            <div
                className="lg:px-3 lg:mb-8 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    SALES DIRECTORS
                </div>
                <img
                    src="https://ik.imagekit.io/dnddecpho/dndream/D&D_Website_SD2025_TU88FAcwU.png?updatedAt=1739961899424"
                    alt=""
                />
            </div>

            <div
                className="lg:px-3 lg:mb-8 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    PROJECT DIRECTORS
                </div>
                <img
                    src="https://ik.imagekit.io/dnddecpho/dndream/D&D_Website_PD2025_RKHS8qNy9.png?updatedAt=1739961899406"
                    alt=""
                />
            </div>

<div
    className="lg:px-3 lg:mb-8 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl grid lg:grid-cols-2 border-b border-t grid-container"
    style={{ fontFamily: "Molde-Expanded-Semibold" }}
>
    <div className="md:pt-1 lg:pt-4 py-1 uppercase grid-item">
        <div className="text-3xl lg:text-6xl primalbrown">252</div>
        <div className="text-3xl lg:text-xl">LEADERS</div>
    </div>

    <div className="md:pt-1 lg:pt-4 py-1 uppercase grid-item">
        <div className="text-3xl lg:text-6xl primalbrown">112</div>
        <div className="text-3xl lg:text-xl">PROJECT COORDINATORS</div>
    </div>
</div>


            <div
                className="lg:px-3 lg:mb-8 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    D&D TRAINERS
                </div>
                <img
                    src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-21_ZLZJpbmm4.png?updatedAt=1719761929806"
                    alt=""
                />
            </div>
        </div>
    );
};
